import { useEffect, useState } from 'react';
import classes from './GmwWaitlistPage.module.css';
import colorblock from '../../Assets/Images/colorblock.png';
import colortower from '../../Assets/Images/colortower.png';
import gmwblacklogo from '../../Assets/Images/black.svg';
import seki from '../../Assets/Images/Seki.png';
import jamal from '../../Assets/Images/jamal.png';
import gambit from '../../Assets/Images/gambit.png';
import selfie from '../../Assets/Images/selfiecam.png';
import Button from '../../Components/Button/Button';
import Aos from 'aos';
import 'aos/dist/aos.css';
import GmwWaitlistForm from './GmwWaitlistForm';

const GmwWaitlistPage = () => {
  const [email, setEmail] = useState('');
  const [selectedLC, setSelectedLC] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({ email: '', select: '' });

  const ourLCs = [
    {
      name: 'Abeokuta',
    },
    {
      name: 'Abuja',
    },
    {
      name: 'Akure',
    },
    {
      name: 'Benin',
    },
    {
      name: 'Benue',
    },
    {
      name: 'Calabar',
    },
    {
      name: 'Enugu',
    },
    {
      name: 'Ibadan',
    },
    {
      name: 'Ife',
    },
    {
      name: 'Ilorin',
    },
    {
      name: 'Jos',
    },
    {
      name: 'Lagos',
    },
    {
      name: 'Portharcourt',
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const validateLCSelection = () => {
    let newErrors = { email: '', select: '' };
    if (!email.trim()) newErrors.email = 'Email is required';
    if (!selectedLC) newErrors.select = 'Please select your LC';
    setErrors(newErrors);
    return !newErrors.email && !newErrors.select;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateLCSelection()) {
      setSubmitted(true);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img src={gmwblacklogo} alt="the gmw logo in black color" />
      </div>
      <div className={classes.heroSection}>
        <div className={classes.heroTextSection}>
          <h4>Level up your Money game!</h4>
          <p>
            Join AIESEC in Nigeria and get ready to{' '}
            <span className="font-bold"> Think Before You Follow</span>. Learn
            to make wiser financial decisions by being a part of the exciting
            activities and events for Global Money Week, happening all over the
            country!
          </p>
          <div>
            <Button>
              <a href="#waitlist-form"> Reserve your Spot</a>
            </Button>
          </div>
        </div>
        <div className={`${classes.heroImage} ml-auto`}>
          <div className={classes.colorblock}>
            <img src={colorblock} alt="a block of colors" />
          </div>
          <div className={classes.colorTower}>
            <img src={colortower} alt="a block of colors" />
          </div>
        </div>
      </div>
      <div className={classes.aboutSection}>
        <h4>What is Global Money Week?</h4>
        <div className={classes.aboutImageSection}>
          <div className={classes.aboutImage}>
            <img src={seki} alt="a young lady " />
          </div>
        </div>
        <p>
          There’s no sustenance without money, and young people like you need
          all the resources you can get to be sure you make the best financial
          decisions possible in your lifetime.
          <br />
          <br />
          That is why{' '}
          <span className={classes.highLight}>Global Money Week,</span> a
          week-long awareness campaign, exists to ensure you are financially
          aware and get all the skills and behaviors you need to achieve
          ultimate financial well-being at the right standards.
        </p>
      </div>
      <div className={`${classes.whySection} `}>
        <h4>why you should be a part?</h4>
        <div className={classes.whyImageSection}>
          <div className={classes.partOne}>
            <div className={classes.partImage}>
              <img src={gambit} alt="a young man " />
            </div>
            <div className={classes.partImage}>
              <img src={jamal} alt="a young man " />
            </div>
            <div className={classes.partTwo}>
              <img src={selfie} alt="a young lady " />
            </div>
          </div>
        </div>
        <p>
          The Global Money Week experience is designed to equip you with the
          skills and knowledge to make informed financial decisions. You'll
          learn from industry experts, network with like-minded individuals, and
          develop a better approach to your financial choices. Join us.
        </p>
      </div>
      <section id="waitlist-form" className={classes.waitListSection}>
        <h4>RESERVE YOUR SPOT!</h4>
        <p>
          In all 13 local chapters of AIESEC in Nigeria, there are different
          activities and events planned out for young people like you. So, to be
          a part of global money week in AIESEC in Nigeria and get your money
          game up, look out for the activity closest to you and show up!{' '}
        </p>
        {!submitted ? (
          // <form onSubmit={handleSubmit} className="flex flex-col  items-center">
          //   <div className="flex items-center justify-center gap-4">
          //     <div className={classes.LCSection}>
          //       <label className="italic">Your Local Committee</label>
          //       <select
          //         value={selectedLC}
          //         onChange={(e) => setSelectedLC(e.target.value)}
          //         className={`bg-white mt-3  px-6 py-2 border text-base text-[#CACCD1] italic shadow-[4px_4px_0px_0px_#000] border-[#20252F]`}
          //         name="LC"
          //         id="LC"
          //         defaultValue={''}
          //       >
          //         <option value="" disabled>
          //           Select your LC
          //         </option>
          //         {ourLCs.map((LC, index) => {
          //           return (
          //             <option
          //               value={LC.name}
          //               key={index}
          //               className="text-[#20252F] "
          //             >
          //               {LC.name}
          //             </option>
          //           );
          //         })}
          //       </select>
          //       {errors.select && (
          //         <p className="mt-2 text-red-500 text-sm">{errors.select}</p>
          //       )}
          //     </div>
          //     <div>
          //       <label className="italic">Your Email Address</label>
          //       <input
          //         type="email"
          //         value={email}
          //         onChange={(e) => setEmail(e.target.value)}
          //         className={`bg-white mt-3 px-6 py-2 border text-base text-[#CACCD1] italic shadow-[4px_4px_0px_0px_#000] border-[#20252F]`}
          //         placeholder="example@gmail.com"
          //       />
          //       {errors.email && (
          //         <p className="mt-2 text-red-500 text-sm">{errors.email}</p>
          //       )}
          //     </div>
          //   </div>
          //   <button className="mt-10 bg-[#008F4F] text-white px-10 py-3 rounded-lg cursor-pointer hover:opacity-55">
          //     Join Waitlist
          //   </button>
          // </form>
          <GmwWaitlistForm />
        ) : (
          <></>
        )}
        {/* <div className={classes.ourLCSection}>
          {ourLCs.map((data, i) => {
            return (
              <div key={i} className={classes.LC} data-aos="fade-down">
                <span>{data.name}</span>
              </div>
            );
          })}
        </div> */}
      </section>
    </div>
  );
};

export default GmwWaitlistPage;
