import sanityClient from "../sanityClient";

export async function getBlogArticleBySlug(slug) {
  return sanityClient.fetch(
    `*[_type == "blogArticle" && slug.current == $slug][0]{
      title,
      "slug": slug.current,
      coverImage {
        "url": asset->url
      },
      "caption": caption[],
      "author": author->{
        name,
        "imageUrl": image.asset->url
      },
      postedDate,
      content[] {
        _type,
        _type == 'subtitle' => {
          subtitle
        },
        _type == 'paragraph' => {
          text[]
        },
        _type == 'imageSection' => {
          "url": image.asset->url,
          caption
        },
        _type == 'youtubeVideo' => {
          videoId,
          caption
        }
      }
    }`,
    { slug }
  )
}

export async function getBlogArticles(start, end){
  return sanityClient.fetch(
    `*[_type == "blogArticle"] | order(postedDate desc)[${start}...${end}]{
        title,
        "slug": slug.current,
        "coverImage": coverImage.asset->url,
        "author": author->{
          name
        },
        postedDate,
        content[0]
      }`
  )
}

export async function getMoreBlogArticles(currentSlug, limit = 2) {
  return sanityClient.fetch(
    `*[_type == "blogArticle" && slug.current != $currentSlug][0...$limit]{
      title,
      "slug": slug.current,
      "coverImage": coverImage.asset->url,
      content[0]
    }`,
    { currentSlug, limit }
  )
}