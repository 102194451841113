import classes from './GmwAboutPage.module.css';
import smiley from '../../Assets/Images/gmwImage1.png';
import schoolkids from '../../Assets/Images/gmwImage2.png';
import blue from '../../Assets/Images/gmwImage3.png';
import board from '../../Assets/Images/gmwImage4.png';
import chris from '../../Assets/Images/gmwImage5.png';
import semiloore from '../../Assets/Images/gmwImage6.png';
import sporty from '../../Assets/Images/gmwImage7.png';
import walkman from '../../Assets/Images/gmwImage8.png';
import hijab from '../../Assets/Images/gmwImage9.png';
import ibadan from '../../Assets/Images/gmwImageX.png';
import akure from '../../Assets/Images/gmwImageXI.png';
import benue from '../../Assets/Images/gmwImageXII.png';
import ellipse from '../../Assets/svgs/Ellipse 7.svg';

const GmwAboutPage = () => {
  return (
    <div className={classes.container}>
      <div className={classes.aboutContainer}>
        <div className={classes.aboutText}>
          <h4>About Gmw</h4>
          <p>
            <span>Global Money Week </span>is an annual awareness campaign
            created to make sure young people like you in different parts of the
            world have all the knowledge you need to make the best financial
            decisions and attain financial well-being at whatever phase you are
            in.
            <br />
            <br />
            So, whether you’re a tech bro, just a student or a working-class
            babe, GMW exists to help you learn how to manage your finances.
          </p>
        </div>
        <div className={classes.aboutImage}>
          <img src={smiley} alt="two young ladies smiling at the camera" />
          <img
            src={schoolkids}
            alt="group of school children wearing white and blue"
            className="border-2 h-[50%] w-full ml-auto"
          />
        </div>
      </div>
      <div className={classes.displayContainer}>
        <div>
          <img src={blue} alt="a young lady speaking" />
        </div>
        <div>
          <img src={board} alt="a young lady speaking" />
        </div>
        <div>
          <img src={chris} alt="a young lady speaking" />
        </div>
        <div>
          <img src={semiloore} alt="a young lady speaking" />
        </div>
        <div>
          <img src={sporty} alt="a young lady speaking" />
        </div>
        <div>
          <img src={walkman} alt="a young lady speaking" />
        </div>
      </div>
      <div className={classes.focusContainer}>
        <div className={classes.focusTextSection}>
          <h4>The focus of gmw</h4>
          <p>
            The focus is to equip young people with the skills to manage their
            finances effectively using available resources.  We're talking about
            learning <span>how to make your money work for YOU</span>
          </p>
        </div>
        <div className={classes.focusImageSection}>
          <div>
            <img src={hijab} alt="a young lady speaking" />
          </div>
          <div>
            <img src={ibadan} alt="a young lady speaking" />
          </div>
          <div>
            <img src={akure} alt="a young lady speaking" />
          </div>
          <div>
            <img src={benue} alt="a young lady speaking" />
          </div>
        </div>
      </div>
      <div className=" relative overflow-hidden w-full transform -skew-y-6">
        <div className="bg-white  min-w-[100vw] py-2">
          <div className=" animate-marquee marque-container whitespace-nowrap p-3 flex items-center gap-2 ">
            <p>
              Financial tip : Your savings cannot save you; Invest your money to
              secure your future
            </p>
            <img src={ellipse} alt="a yellow ellipse" />
            <p>
              Financial tip : Your savings cannot save you; Invest your money to
              secure your future
            </p>
          </div>
        </div>
      </div>

      <div className="h-28 md:h-40 bg-transparent"></div>

      <div className={classes.NextSection}>
        <div className={classes.NextTextSection}>
          <h4>What's Next?</h4>
          <p>
            This year's Global Money Week promises to be packed with knowledge,
            engaging events, and opportunities. Choose to get involved to
            discover how you can participate, learn, and grow with AIESEC in
            Nigeria.
          </p>
        </div>
        <div className={classes.ExploreButton}>
          <a href="/gmw/2025">Get Involved!</a>
        </div>
      </div>
    </div>
  );
};

export default GmwAboutPage;
