import Layout from '../../Components/Layout/Layout';
import GmwHomePageHero from '../../Containers/GmwHomePageHero/GmwHomePageHero';
import GmwAboutPage from '../../Containers/GmwAboutPage/GmwAboutPage';

const GmwHome = () => {
  return (
    <Layout>
      <div className="w-full">
        <GmwHomePageHero />
        <GmwAboutPage />
      </div>
    </Layout>
  );
};

export default GmwHome;
