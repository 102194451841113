import Layout from "../../Components/Layout/Layout";
// import BlogHero from "../../Containers/BlogHero/BlogHero";
import BlogHeroNew from "../../Containers/BlogHero/BlogHeroNew";

const Blog = () => {
  return (
    <Layout>
      {/* <BlogHero /> */}
      <BlogHeroNew />
    </Layout>
  );
};

export default Blog;
