import { useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import classes from './PagesContainer.module.css';
import AIESECMember from '../../Assets/Images/AIESECMember.svg';
import AIESECMemberLogo from '../../Assets/Images/AIESEC MEmber Logo.svg';
import videoPlayButton from '../../Assets/Images/videoPlayButton.svg';
import FormContainer from '../../Containers/FormContainer/FormContainer';
// import AboutUsLocalCommittees from "../../Containers/AboutUsLocalCommittees/AboutUsLocalCommittees";
import MembershipRole from '../../Containers/MembershipRoles/MembershipRoles';
import LandingPageTestimonials from '../../Containers/LandingPageTestimonials/LandingPageTestimonials';
import Aos from 'aos';
import 'aos/dist/aos.css';
// import RegistrationSteps from '../../Containers/RegistrationSteps/RegistrationSteps';
import OurLocalCommittees from '../../Containers/OurLocalCommittees/OurLocalCommittees';

const PagesContainer = ({
  heroImage,
  titleText,
  subText,
  organizations,
  duration,
  notshowOrganization,
}) => {
  // Refs
  // const scrollableRef = useRef(null),

  //   Effects
  // useEffect(() => {
  //   const scrollableElement = scrollableRef.current,
  //   let currentIndex = 0,

  //   const scrollNextBox = () => {
  //     if (currentIndex < scrollableElement.children.length - 1) {
  //       currentIndex++,
  //       const nextBox = scrollableElement.children[currentIndex],
  //       scrollableElement.scrollTo({
  //         top: 0,
  //         left: nextBox.offsetLeft,
  //         behavior: "smooth",
  //       }),
  //     } else {
  //       currentIndex = 0,
  //       scrollableElement.scrollTo({
  //         top: 0,
  //         left: 0,
  //         behavior: "smooth",
  //       }),
  //     }
  //   },

  //   const scrollInterval = setInterval(scrollNextBox, 5000),

  //   return () => {
  //     clearInterval(scrollInterval),
  //   },
  // }, []),

  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const handlePlayButtonClick = () => {
    setIsVideoVisible(true);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <Layout>
      <section className={classes.container}>
        <div className={`${classes.heroImageSection} mb-8`}>
          <img src={heroImage} alt="AIESEC" loading="lazy" />
        </div>
        <div className={`flex items-center mb-10 w-full`}>
          <div className={`${classes.titleSection} mx-auto`}>
            <h2>
              <span className={`${classes.aboutText} font-extralight`}>
                Become an
              </span>{' '}
              AIESEC Member
            </h2>
          </div>
        </div>
        <hr
          style={{
            width: '100%',
            border: '0.1px solid #E1E1E1',
            margin: '0 auto',
          }}
        />
        <div className={`${classes.aboutContainer} `}>
          <div
            className={`${classes.aboutContainerText} bg-[#037ef3] text-white`}
          >
            <h6>
              <span className={`${classes.aboutText} font-extralight`}>
                ABOUT
              </span>{' '}
              AIESEC
            </h6>
            <p className="font-light">
              AIESEC is a global platform for young people to develop their
              leadership potential through international internships and
              volunteer opportunities. We facilitate and promote cultural
              understanding and develop socially responsible, proactive agents
              of change with a view of making a positive impact in the world
              through our International Exchange and Membership Program.
            </p>
          </div>

          <div className={classes.mediaView}>
            {!isVideoVisible && (
              <img
                src={videoPlayButton}
                alt="video player"
                className={classes.playButton}
                onClick={handlePlayButtonClick}
                aria-label="Play Video"
              />
            )}
            <iframe
              width="660"
              height="315"
              src="https://www.youtube.com/embed/jRuw-UWrRkc?si=vAinxpG2OnSDU0Jn"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className={classes.videoFrame}
            ></iframe>
          </div>
        </div>
        <div>
          <div className={classes.roles}>
            <div className={classes.topImage}>
              <img
                src={AIESECMember}
                alt="AIESEC member large logo"
                className="hidden md:block"
                loading="lazy"
              />
              {/* Image for small screens */}
              <img
                src={AIESECMemberLogo}
                alt="AIESEC member small logo"
                className="block md:hidden "
                loading="lazy"
              />
            </div>
            <div className={`${classes.rolesText} mx-auto`}>
              <h2>
                <span className={`${classes.aboutText} font-extralight`}>
                  Our{' '}
                </span>
                roles
              </h2>
              <p>
                When you join one of our local AIESEC teams, you are assigned to
                a smaller team in a specific department. Think of it as a
                simulation of the departments in any organization or company,
                but your impact will be a real one.
              </p>
            </div>
          </div>
          <MembershipRole />
          <div className={classes.blueDash}></div>
        </div>

        {/* <AboutUsLocalCommittees isComponent={true} /> */}
        <OurLocalCommittees />
        <LandingPageTestimonials />
        {/* <RegistrationSteps /> */}
        <FormContainer />
      </section>
    </Layout>
  );
};

export default PagesContainer;
