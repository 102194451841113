import { Link } from "react-router-dom";
import classes from "./LandingPageNewContent.module.css";
import { getBlogArticles } from '../../Utilities/getBlogPage';
import { useEffect, useState } from "react";
import { PortableText } from '@portabletext/react';


const LandingPageNewContent = () => {
  const [blogArticles, setBlogArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const posts = await getBlogArticles(0, 2);
        setBlogArticles(posts);
      } catch (error) {
        console.error("Error fetching blog articles:", error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className={classes.container}>
      <p>EXPLORE</p>
      <h4>The newest content</h4>
      <div className={classes.contentContainer}>
        {blogArticles.map((article, i) => {
          return (
            <div className={classes.content} key={i}>
              <div className={classes.imageSection}>
                <img src={article.coverImage} alt={article.title} loading="lazy" />
              </div>
              <div className={classes.textSection}>
                <h4>{article.title} </h4>
                <PortableText value={article.content.text} />
                <Link
                  to={`/blog/${article.slug}`}
                >
                  Read More{"    "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="8"
                    viewBox="0 0 15 8"
                    fill="none"
                  >
                    <path
                      d="M14.3536 4.35355C14.5488 4.15829 14.5488 3.84171 14.3536 3.64645L11.1716 0.464466C10.9763 0.269204 10.6597 0.269204 10.4645 0.464466C10.2692 0.659728 10.2692 0.976311 10.4645 1.17157L13.2929 4L10.4645 6.82843C10.2692 7.02369 10.2692 7.34027 10.4645 7.53553C10.6597 7.7308 10.9763 7.7308 11.1716 7.53553L14.3536 4.35355ZM0.5 4.5H14V3.5H0.5V4.5Z"
                      fill="#037EF3"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes.explore}>
        <Link to="/blog">
          Explore more{"   "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              opacity="0.7"
              d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
              stroke="#037EF3"
              stroke-width="1.43396"
            />
          </svg>
        </Link>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="140"
          height="9"
          viewBox="0 0 140 9"
          fill="none"
        >
          <path
            d="M1 8C32.9147 8 64.6091 2.62996 96.6541 2.42885C109.944 2.34544 123.112 2.27783 136.382 2.42885C139.977 2.46976 139.818 1.86774 136.221 1.80981C129.836 1.707 123.967 0.769562 117.445 1.05324C105.402 1.57703 93.3188 1.20215 81.2624 1.67226C56.6761 2.63093 32.0651 2.27219 7.52737 3.04786"
            stroke="#037EF3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default LandingPageNewContent;
