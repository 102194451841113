import classes from './GmwHomePageHero.module.css';
import gmwlogo from '../../Assets/Images/gmwlogowhite.svg';
import frame from '../../Assets/Images/blockcolor.svg';

const GmwHomePageHero = () => {
  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img src={gmwlogo} alt="the gmw logo in white color" />
      </div>
      <div className={classes.heroText}>
        <h3>
          Think before you follow, wise money <span>tomorrow</span>
        </h3>
        <p className="w-[90%] md:w-[100%] lg:w-[70%] m-auto">
          This year's theme aims to encourage young people to embrace financial
          literacy that will eventually lead to financial freedom through decent
          work. AIESEC in Nigeria, during the Global Money Week, is set to equip
          young people with the knowledge, skills, and mindset necessary to
          achieve financial freedom, contribute to the country's economic
          growth, and become responsible global citizens.
        </p>
      </div>
      <div className={classes.colorContainer}>
        <img src={frame} alt="a block of different colors" />
      </div>
    </div>
  );
};

export default GmwHomePageHero;
