import Layout from '../Layout/Layout';
import classes from './NvFormLayout.module.css';
import eyeIllustration from '../../Assets/svgs/eYE ILLUSTRATION.svg';

const NvFormLayout = ({ children, notShowFooter }) => {
  return (
    <Layout noFooter notShowFooter={notShowFooter}>
      <div className={classes.container}>
        <div className={classes.upperSection}>
          <div className="flex items-center justify-center gap-14">
            <img src={eyeIllustration} alt="NV eye" className="mr-auto" />
            <h4>
              SIGN UP TO BE <span>A NATIONAL VOLUNTEER</span>
            </h4>
          </div>
        </div>
        <div className={classes.lowerSection}>
          <div>
            <p>
              Thank you for indicating interest to be a part of the National
              Volunteer Program.
            </p>
            <p>
              Please provide us with the necessary information required to
              curate an amazing experience for you. We can't wait to have you!
            </p>

            <i>
              Disclaimer: You cannot apply for a project in your city! Only
              apply for projects in other states
            </i>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </Layout>
  );
};

export default NvFormLayout;
