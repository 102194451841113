// CookieConsentApp.js
import { useCookies } from 'react-cookie';
import { useState } from 'react';
import ManageCookiesPreference from './ManageCookiesPreference';

const CookieConsentApp = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isManagingCookies, setIsManagingCookies] = useState(false);
  const [preferences, setPreferences] = useState({
    analytics: cookies.analytics || false,
    marketing: cookies.marketing || false,
    necessary: true,
  });

  const cookieExpiryMonths = 6;
  const getExpiryDate = () => {
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + cookieExpiryMonths);
    return expiryDate;
  };

  const handleAcceptCookies = () => {
    const expiryDate = getExpiryDate();
    setCookie('cookieConsent', true, { path: '/', expires: expiryDate });
    setCookie('analytics', preferences.analytics, {
      path: '/',
      expires: expiryDate,
    });
    setCookie('marketing', preferences.marketing, {
      path: '/',
      expires: expiryDate,
    });
    console.log('All cookies accepted.');
    setIsModalOpen(false);
  };

  const handleSavePreferences = () => {
    setPreferences((prev) => {
      const expiryDate = getExpiryDate();
      setCookie('analytics', preferences.analytics, {
        path: '/',
        expires: expiryDate,
      });
      setCookie('marketing', preferences.marketing, {
        path: '/',
        expires: expiryDate,
      });
      // setIsManagingCookies(false);
      console.log('Cookie preferences saved.');
      // setIsModalOpen(false);
      setTimeout(() => {
        setIsManagingCookies(false);
        setIsModalOpen(false);
      }, 100);
      return prev;
    });
  };

  const togglePreference = (type) => {
    setPreferences((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  return isModalOpen ? (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      {isManagingCookies ? (
        <ManageCookiesPreference
          isOpen={isManagingCookies}
          onClose={() => setIsManagingCookies(false)}
          preferences={preferences}
          togglePreference={togglePreference}
          handleSavePreferences={handleSavePreferences}
        />
      ) : (
        <div className="font-sec bg-white max-w-[90vw] md:max-w-[500px] h-auto p-6 sm:p-10 shadow-lg gap-6 flex flex-col rounded-lg">
          <h2 className="font-bold leading-8 text-black text-2xl md:text-3xl">
            Cookie Consent
          </h2>
          <p className="text-lg leading-7 sm:leading-8">
            AIESEC in NIGERIA requests your consent to store cookies on your
            terminal equipment and to collect information from your terminal
            equipment for the purposes of improving its services and tailoring
            the marketing you see on apps and other websites. You can change
            your mind by clicking the “Cookies” button.
          </p>
          <div className="mt-auto flex flex-col gap-4">
            <button
              onClick={handleAcceptCookies}
              className="rounded-lg py-3 sm:py-[14px] px-8 sm:px-[120px] bg-[#037ef3] text-white text-lg sm:text-xl w-full hover:opacity-65"
            >
              Accept all cookies
            </button>
            <button
              onClick={() => setIsManagingCookies(true)}
              className="rounded-lg border border-[#dcdcdc] text-lg sm:text-xl py-3 sm:py-[14px] px-8 sm:px-[120px] w-full hover:text-[#037ef3] hover:border-[#037ef3]"
            >
              Manage cookies
            </button>
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default CookieConsentApp;
