import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import classes from "./BlogPage.module.css";
import ShareButton from '../../Components/ShareButton/ShareButton';
import { getBlogArticleBySlug, getMoreBlogArticles } from '../../Utilities/getBlogPage';
import { Link } from 'react-router-dom';
import { scrollToTheTop } from "../../Utilities/scrollToTop";
import {PortableText} from '@portabletext/react'

function BlogPage() {
    const { slug } = useParams();
    const [article, setArticle] = useState(null);
    const [moreArticles, setMoreArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function loadBlogData() {
        if (!slug) return;
        
        try {
            setIsLoading(true);
            const [articleData, moreArticleData] = await Promise.all([
                getBlogArticleBySlug(slug),
                getMoreBlogArticles(slug)
            ]);
            
            setArticle(articleData);
            setMoreArticles(moreArticleData);
        } catch (error) {
            console.error('Failed to load blog data:', error);
        } finally {
            setIsLoading(false);
        }
        }

        loadBlogData();
    }, [slug]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!article) {
        return <div>Article not found</div>;
    }

    const renderContent = (content, index) => {
        switch (content._type) {
        case 'subtitle':
            return <h3 key={index}>{ content.subtitle }</h3>;
        case 'paragraph':
            console.log(content.text);
            return (
                <div key={index}>
                  <PortableText 
                    value={content.text}
                    components={{
                      block: {
                        normal: ({children}) => <p>{children}</p>,
                        h3: ({children}) => <h3 className={classes.BlogArticleSub}>{children}</h3>,
                        h4: ({children}) => <h4>{children}</h4>,
                        blockquote: ({children}) => <blockquote>{children}</blockquote>,
                      },
                      marks: {
                        link: ({value, children}) => {
                          const { href, blank } = value;
                          return blank ? 
                            <a href={href} target="_blank" rel="noopener noreferrer" className={classes.defaultLink}>{children}</a> :
                            <a href={href} className={classes.defaultLink}>{children}</a>;
                        }
                      }
                    }}
                  />
                </div>
            );

        case 'imageSection':
            return (
            <figure key={index}>
                <img src={content.url} alt={content.caption || ''} />
            </figure>
            );
        case 'youtubeVideo':
            return (
            <div key={index}>
                <iframe 
                    width="100%" 
                    height="510" 
                    src={content.videoId} 
                    title={content.title}
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                ></iframe>
            </div>
            );
        default:
            return null;
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const day = date.getDate();
        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
 
        const dayWithSuffix = day + getOrdinalSuffix(day);
        const options = { month: 'long' };
        const monthName = new Intl.DateTimeFormat('en-US', options).format(date);
        const year = date.getFullYear();

        return `${dayWithSuffix} ${monthName}, ${year}`;
    };

    return (
        <section className={classes.container}>
            <div className={classes.blogHeading}>
                <div className={classes.blogHeadingTitle}>
                    <span>{ formatDate(article.postedDate) }</span>
                    <h1>{article.title}</h1>
                    <span className={classes.blogHeadingTitleCaption}><PortableText value={article.caption} /></span>

                    <div className={classes.blogHeadingTitleAuthor}>
                        <img src={article.author.imageUrl} alt={`${article.author.name}`} />
                        <span>By {article.author.name}</span>
                    </div>
                </div>

                <div className={classes.blogHeadingImg}>
                    <img src={article.coverImage.url} alt={article.title} />
                </div>
            </div>

            <div className={classes.blogContent}>
                {article.content.map((content, index) => renderContent(content, index))}

                <div className={classes.shareBlogContent}>
                <span>Share this post</span>
                <ShareButton 
                    text={`Check out this amazing article! "${article.title}"`} 
                    linktext={`http://aiesec.ng/blog/${slug}`} 
                />
                </div>
            </div>

        
            <div className={classes.blogsRelated}>
                <h4>More Articles</h4>
                <div className={classes.blogsRelatedContainer}>
                    {moreArticles.map((relatedArticle) => (
                    <Link 
                        key={relatedArticle.slug}
                        onClick={scrollToTheTop} 
                        className={classes.featuredBlogLinkContainer} 
                        to={`/blog/${relatedArticle.slug}`}
                    >
                        <div className={classes.blogCard}>
                        <div className={classes.blogCardImgContainer}>
                            <img 
                            className={classes.featuredBlogImg} 
                            src={relatedArticle.coverImage} 
                            alt={relatedArticle.title} 
                            />
                        </div>
                        <div className={classes.blogCardContainer}>
                            <h4 className={classes.blogCardTitle}>{relatedArticle.title}</h4>
                            <p className={classes.featuredBlogDesc}><PortableText value={relatedArticle.content.text} /></p>
                            <div className={classes.featuredBlogLink}>
                            <span>Read More</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                            >
                                <path
                                opacity="0.7"
                                d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                                stroke="#037EF3"
                                strokeWidth="1.43396"
                                />
                            </svg>
                            </div>
                        </div>
                        </div>
                    </Link>
                    ))}
                </div>
            </div>
        </section>
    );
  }
  
export default BlogPage
