import { useState, useEffect } from 'react';
import classes from './BlogHero.module.css';
import { Link } from 'react-router-dom';
import { scrollToTheTop } from '../../Utilities/scrollToTop';
import { getBlogArticles } from '../../Utilities/getBlogPage';
import { PortableText } from '@portabletext/react';

const BlogHeroNew = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [moreArticles, setMoreArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  const articlesPerPage = 9;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    async function loadBlogData() {
      try {
        setIsLoading(true);
        const posts = await getBlogArticles(0, 3);
        setBlogPosts(posts);
        const morePosts = await getBlogArticles(3, 9);
        setMoreArticles(morePosts);
        setTotalPages(Math.ceil(morePosts.length / articlesPerPage));
      } catch (error) {
        console.error('Failed to load blog data:', error);
      } finally {
        setIsLoading(false);
      }
    }
    loadBlogData();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    scrollToTheTop();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (blogPosts.length === 0) {
    return <div>No featured posts available.</div>;
  }

  const [featuredPost, ...otherPosts] = blogPosts;
  const startIndex = (currentPage - 1) * articlesPerPage;
  const displayedArticles = moreArticles.slice(
    startIndex,
    startIndex + articlesPerPage
  );

  const mobileMoreArticles = isMobile
    ? [...otherPosts, ...moreArticles]
    : moreArticles;

  function reduceName(str, maxLength=13) {
    if (str.length <= maxLength) {
      return str;
    }
  
    const truncated = str.slice(0, maxLength - 3);
    return truncated.charAt(0).toUpperCase() + truncated.slice(1) + '...';
  }
  
  function formatDateString(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
  
    return date.toLocaleDateString('en-US', options).replace(',', '');
  }  

  return (
    <section className={classes.container}>
      <h4>
        <span>
          <span>Broaden</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="12"
            viewBox="0 0 165 12"
            fill="none"
          >
            <path
              d="M1 11C38.5664 11 75.8735 3.59409 113.593 3.31674C129.237 3.20171 144.737 3.10847 160.357 3.31674C164.588 3.37316 164.401 2.5429 160.167 2.46302C152.652 2.32122 145.744 1.02839 138.066 1.41962C123.89 2.14198 109.667 1.62498 95.4759 2.27331C66.5357 3.59544 37.5664 3.10069 8.6833 4.17042"
              stroke="#037EF3"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </span>{' '}
        your horizon <br />
        with AIESEC
      </h4>

      <div className={classes.mobileLatestLabel}>Latest article</div>

      <div className={classes.blogContainer}>
        <div className={classes.featuredBlog}>
          <Link
            onClick={scrollToTheTop}
            className={classes.featuredBlogLinkContainer}
            to={`/blog/${featuredPost.slug}`}
          >
            <div className={classes.featuredBlogImgContainer}>
              <img
                className={classes.featuredBlogImg}
                src={featuredPost.coverImage}
                alt={featuredPost.title}
              />
            </div>
            <h4 className={classes.featuredBlogTitle}>{featuredPost.title}</h4>
            <p className={classes.featuredBlogDesc}>
              <PortableText value={featuredPost.content.text} />
            </p>
            <span className={classes.featuredBlogLink}>
              <div className="flex items-center gap-6 italic text-[#707071] text-base">
                <p>{featuredPost.author.name}</p>
                <p>{formatDateString(featuredPost.postedDate)}</p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  opacity="0.7"
                  d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                  stroke="#037EF3"
                  strokeWidth="1.43396"
                />
              </svg>
            </span>
          </Link>
        </div>

        <div className={classes.blogCardContainer}>
          {otherPosts.map((post, index) => (
            <Link
              key={index}
              onClick={scrollToTheTop}
              className={classes.featuredBlogLinkContainer}
              to={`/blog/${post.slug}`}
            >
              <div className={classes.blogCard}>
                <div className={classes.blogCardImgContainer}>
                  <img
                    className={classes.featuredBlogImg}
                    src={post.coverImage}
                    alt={post.title}
                  />
                </div>

                <div className={classes.blogCardContainer}>
                  <h4 className={classes.blogCardTitle}>{post.title}</h4>
                  <p className={classes.featuredBlogDesc}>
                    <PortableText value={post.content.text} />
                  </p>
                  <span className={classes.featuredBlogLink}>
                    <div className="flex items-center gap-6 italic text-[#707071] text-base">
                      <p>{reduceName(post.author.name)}</p>
                      <p>{formatDateString(post.postedDate)}</p>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        opacity="0.7"
                        d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                        stroke="#037EF3"
                        strokeWidth="1.43396"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* More Articles section */}
      {(displayedArticles.length > 0 ||
        (isMobile && mobileMoreArticles.length > 0)) && (
        <div className={classes.secondContainer}>
          <div className={classes.maline} />
          <h4>More articles</h4>

          <div className={classes.moreArticlesGrid}>
            {(isMobile ? mobileMoreArticles : displayedArticles).map(
              (article) => (
                <Link
                  key={article.slug}
                  onClick={scrollToTheTop}
                  className={classes.articleCard}
                  to={`/blog/${article.slug}`}
                >
                  <div className={classes.articleContainer}>
                    <div className={classes.articleImageContainer}>
                      <img
                        className={classes.articleImage}
                        src={article.coverImage}
                        alt={article.title}
                      />
                    </div>

                    <div className={classes.articleContent}>
                      <h5 className={classes.articleTitle}>{article.title}</h5>
                      <p className={classes.articleDesc}>
                        <PortableText value={article.content.text} />
                      </p>
                      <span className={classes.featuredBlogLink}>
                        <div className="flex items-center gap-6 italic text-[#707071] text-base">
                          <p>{article.author.name}</p>
                          <p>{formatDateString(article.postedDate)}</p>
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            opacity="0.7"
                            d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                            stroke="#037EF3"
                            strokeWidth="1.43396"
                          />
                        </svg>
                      </span>
                      <span className={classes.articleDate}>
                        {article.publishedAt}
                      </span>
                    </div>
                  </div>
                </Link>
              )
            )}
          </div>

          {/* Pagination Container */}
          {displayedArticles.length === 1 ? null : (
            <div className={classes.paginationContainer}>
              <button
                className={`${classes.paginationArrow} ${
                  isMobile ? classes.mobileArrow : ''
                }`}
                onClick={() =>
                  currentPage > 1 && handlePageChange(currentPage - 1)
                }
                disabled={currentPage === 1}
              >
                &lt;
              </button>

              {!isMobile ? (
                // Desktop pagination
                [1, 2, 3].map((page) => (
                  <button
                    key={`page-${page}`}
                    className={`${classes.paginationButton} ${
                      currentPage === page ? classes.active : ''
                    }`}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                ))
              ) : (
                // Mobile pagination
                <div className={classes.paginationButton}>
                  {[1, 2, 3].map((page) => (
                    <button
                      key={`page-dot-${page}`}
                      className={`${classes.paginationButton} ${
                        currentPage === page ? classes.active : ''
                      }`}
                      onClick={() => handlePageChange(page)}
                      aria-label={`Page ${page}`}
                    />
                  ))}
                </div>
              )}

              <button
                className={`${classes.paginationArrow} ${
                  isMobile ? classes.mobileArrow : ''
                }`}
                onClick={() =>
                  currentPage < totalPages && handlePageChange(currentPage + 1)
                }
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default BlogHeroNew;
