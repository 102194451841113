import Layout from '../../Components/Layout/Layout';
import BlogPage from '../../Containers/BlogPage/BlogPage';


const BlogPages = () => {
    return (
        <Layout>
            <BlogPage />
        </Layout>
    );
}

export default BlogPages;